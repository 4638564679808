import React, { useRef, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useMeasure } from 'react-use'
import { Button, Flex } from 'antd'
import { CarOutlined, ClusterOutlined } from '@ant-design/icons'
import SVG from 'react-inlinesvg'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useCDN } from '../hooks/CDN'
import { FormattedMessage } from 'react-intl'
import { ConnectorColorTag } from '../components/ConnectorColorTag/index'
import { ZoomControl } from '../components/ZoomControls'
import Loading from '../components/Loading'
import { DARK_MODE } from '../constants/misc'
import { BLACK_COLOR, WHITE_COLOR } from '../constants/colors'
import { toggleColor } from '../components/SVGColorToggle'

const COMPONENT_IMAGE = 'COMPONENT_IMAGE'
const LOCATION_IMAGE = 'LOCATION_IMAGE'

export const ComponentOrLocationImage = ({
  componentImageName,
  locationImageName,
  componentId,
  pins,
  description,
  alias,
  children,
  isComponentImagePDBox = false,
  onFuseClick,
  onImageSwitch = null,
  color = null,
  isImagePanelExanded = true,
}) => {
  const [view, setView] = React.useState(LOCATION_IMAGE)
  const props = useSelector((state) => {
    return {
      theme: state.app.theme,
    }
  }, shallowEqual)

  return (
    <div className="functional-diagrams-component-or-location-view">
      <div className="functional-diagrams-component-or-location-view-image">
        {componentId ? (
          view === COMPONENT_IMAGE ? (
            <ComponentImage
              fileName={componentImageName}
              pins={pins}
              isComponentImagePDBox={isComponentImagePDBox}
              onFuseClick={onFuseClick}
              theme={props.theme}
            />
          ) : (
            <LocationImage
              fileName={locationImageName}
              componentId={componentId}
            />
          )
        ) : null}
      </div>
      <Flex
        vertical={false}
        align={'center'}
        justify="space-between"
        className="functional-diagrams-component-or-location-view-ctrls"
      >
        <Flex vertical={false} gap={'large'} style={{ height: '100%' }}>
          <div>
            <h5>
              <FormattedMessage id="componentOrLocation.alias" />
            </h5>
            {alias}
          </div>
          {color && (
            <div>
              <h5>
                <FormattedMessage id="componentOrLocation.color" />
              </h5>
              <ConnectorColorTag colorCode={color} />
            </div>
          )}
          {isImagePanelExanded && (
            <div>
              <h5>
                <FormattedMessage id="componentOrLocation.description" />
              </h5>
              {description}
            </div>
          )}
        </Flex>
        <Flex vertical={false} justifyContent={'space-between'} gap="large">
          <div>{children}</div>
          <Button
            icon={
              view === COMPONENT_IMAGE ? <CarOutlined /> : <ClusterOutlined />
            }
            onClick={() => {
              if (view === COMPONENT_IMAGE) {
                setView(LOCATION_IMAGE)
              }
              if (view === LOCATION_IMAGE) {
                setView(COMPONENT_IMAGE)
              }
              if (onImageSwitch) {
                onImageSwitch()
              }
            }}
            data-testid="location-or-component-toggle"
          />
        </Flex>
      </Flex>
    </div>
  )
}

export const LocationImage = ({
  fileName,
  componentId,
  showControls = true,
  onSVGLoad = null,
  clickHandler = null,
  selectedElementId = null,
}) => {
  const onClick = (event) => {
    if (event.target.id && event.currentTarget.children.circles) {
      event.target.setAttribute('fill-opacity', '0.30')
      const allChildren = [...event.currentTarget.children.circles.children]
      const overlap = allChildren.filter((child) => {
        if (
          selectedElementId &&
          selectedElementId === child.id &&
          selectedElementId !== event.target.id
        ) {
          child.setAttribute('fill-opacity', '0')
        }
        const targetBBox = event.target.getBBox()
        const childBBox = child.getBBox()
        return (
          Math.abs(targetBBox.y - childBBox.y) <= targetBBox.height &&
          Math.abs(targetBBox.x - childBBox.x) <= targetBBox.width
        )
      })
      clickHandler(overlap, event.target.id)
    } else if (!event.target.id && event.currentTarget.children.circles) {
      const circle = event.currentTarget.getElementById(`${selectedElementId}`)
      if (circle) {
        circle.setAttribute('fill-opacity', '0')
      }
      clickHandler()
    }
  }

  const svgPostProcess = ({ svgRef }) => {
    svgRef.current.setAttribute('data-testid', 'location-image')
    const circle = svgRef.current.getElementById(`_${componentId}`)

    if (!circle) return
    circle.setAttribute('stroke-opacity', '1')
    circle.setAttribute('fill-opacity', '0.20')

    if (selectedElementId) {
      const element = svgRef.current.getElementById(`${selectedElementId}`)
      element && element.setAttribute('fill-opacity', '0.30')
    }

    if (onSVGLoad) {
      const allChildren = [...svgRef.current.children.circles.children]
      const ids = allChildren.map((child) => child.id)
      onSVGLoad(ids)
    }
  }

  return (
    <div className="location-image-container">
      <Image
        key={`${fileName}-${componentId}`}
        fileName={fileName}
        svgPostProcess={svgPostProcess}
        showControls={showControls}
        clickHandler={clickHandler ? onClick : null}
      />
    </div>
  )
}

export const ComponentImage = ({
  fileName,
  pins = [],
  showControls = true,
  isComponentImagePDBox = false,
  onFuseClick = null,
  theme,
}) => {
  const selectedElement = useRef(null)

  const updatePDBoxColor = (pins, svgRef, color) => {
    const fuseGroup = svgRef.current.getElementById(`FUSE`)

    if (fuseGroup && fuseGroup.childNodes) {
      fuseGroup.childNodes.forEach((element) => {
        element.style.pointerEvents = 'none'
      })

      const textTags = svgRef.current.querySelectorAll('text')
      textTags.forEach((element) => {
        if (
          element.hasAttribute('fill') &&
          element.getAttribute('fill') != 'none'
        ) {
          element.setAttribute('fill', color)
        }
      })
    } else {
      updateLineColors(svgRef, color)
    }

    pins.forEach((pinNumber) => {
      const pinElement = svgRef.current.getElementById(`pin_${pinNumber}`)
      if (pinElement && pinElement.childNodes) {
        const pattern = /^fuse_.*/
        pinElement.childNodes.forEach((node) => {
          if (node.id && pattern.test(node.id)) {
            node.childNodes[0].setAttribute('fill', '#E04F4D')
            node.childNodes[0].setAttribute('stroke', color)
            node.childNodes[0].style.cursor = 'pointer'
          }
        })
      }
    })
  }

  const svgPostProcess = ({ svgRef }) => {
    const color = theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR
    svgRef.current.setAttribute('data-testid', 'component-image')
    pins.forEach((pinNumber) => {
      const fillArea = svgRef.current.getElementById(`pin_${pinNumber}_path`)

      if (!fillArea) return
      fillArea.setAttribute('fill', '#E04F4D')
      fillArea.setAttribute('stroke', color)

      const text = svgRef.current.getElementById(`pin_${pinNumber}_text`)
      if (!text) return
      text.setAttribute('fill', 'white')
      text.setAttribute('font-family', 'Montserrat')
    })

    if (isComponentImagePDBox) {
      updatePDBoxColor(pins, svgRef, color)
      return
    }

    updateLineColors(svgRef, color)
  }

  const updateLineColors = (svgRef, color) => {
    const childNodes = svgRef.current.childNodes
    toggleColor(childNodes, color)
  }

  const onClick = (event) => {
    if (isComponentImagePDBox) {
      const pattern = /^fuse_.*/
      const parentID = event.target.parentElement.id
      const pinID = event.target.parentElement.parentElement.id.substring('4')
      if (selectedElement.current) {
        selectedElement.current.setAttribute('stroke', 'white')
      }
      if (pattern.test(parentID) && pins.includes(pinID)) {
        selectedElement.current = event.target
        event.target.setAttribute('stroke', '#40a9ff')
        onFuseClick(event)
      } else {
        onFuseClick(undefined)
      }
    }
  }

  return (
    <div className="component-image-container">
      <Image
        key={`${fileName}-${pins.join('-')}`}
        fileName={fileName}
        svgPostProcess={svgPostProcess}
        showControls={showControls}
        clickHandler={onClick}
        theme={theme}
      />
    </div>
  )
}

const Image = ({
  fileName,
  svgPostProcess,
  clickHandler = () => {},
  showControls,
  theme,
  ...props
}) => {
  const { isSuccess, cdn } = useCDN()
  const [loaded, setIsLoaded] = React.useState(false)
  const svgRef = React.useRef()
  const transformComponentRef = React.useRef()
  const [containerRef, { width, height }] = useMeasure()

  React.useEffect(() => {
    if (!svgRef.current || !loaded) return
    svgPostProcess({ svgRef })
  }, [loaded, theme])

  return (
    <div
      className="tracer-image-viewer"
      ref={containerRef}
      style={{ height: '100%', width: '100%' }}
    >
      {isSuccess && (
        <TransformWrapper
          wheel={{ step: 0.1 }}
          initialScale={1}
          limitToBounds={false}
          minScale={-2}
          panning={{ disabled: false }}
          ref={transformComponentRef}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              {showControls && (
                <ZoomControl
                  zoomIn={() => zoomIn()}
                  zoomOut={() => zoomOut()}
                  zoomReset={() => resetTransform()}
                  className={
                    'zoom-control-button-group detail-diagram-zoom-control'
                  }
                />
              )}
              <TransformComponent
                wrapperStyle={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SVG
                  innerRef={svgRef}
                  onLoad={(src, hasCache) => setIsLoaded(src)}
                  onClick={clickHandler}
                  loader={<Loading showText={false} />}
                  style={{
                    height: height,
                    width: width,
                    maxHeight: height,
                    maxWidth: width,
                  }}
                  src={`${cdn.imagesURL}/${fileName}`}
                  width={height}
                  height={width}
                  fetchOptions={{ credentials: 'include' }}
                  //viewBox={`0 0 ${width} ${height}`}
                  //preserveAspectRatio="xMidYMid meet"
                ></SVG>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </div>
  )
}
