import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import get from 'lodash/get'
import { Popover, Flex, Tooltip, Card } from 'antd'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'

import ListItem from '../../components/ListItem'
import ListPanel from '../ListPanel/ListPanel'
import { dtcItemKey } from '../../helpers/utils'
import Comparator from '../../helpers/comparator'
import { PINOUT_VIEW, VEHICLE_VIEW } from '../../constants'
import MemoButton, { EditIcon, AddIcon } from './DTCMemoButton'
import { getShortDescription } from '../../helpers/dtc'
import DTCMemoTooltip from './DTCMemoTooltip'
import DTCMemoPopup from './DTCMemoPopup'

const MAX_DTC_TITLE_LEN = 13

function MisconfiguredDTCContent({ dtc }) {
  return (
    <div className="dtc-popover-content">
      <div className="title">
        <FormattedMessage id="tracer.dtcListPanel.popover.title" />
      </div>
      <div className="block">
        <div className="block-label">
          <FormattedMessage id="tracer.dtcListPanel.popover.harnessPN" />
        </div>
        <div className="block-text">{dtc.harness_pn}</div>
      </div>
      <div className="block">
        <div className="block-label">
          <FormattedMessage id="tracer.dtcListPanel.popover.harnessDescription" />
        </div>
        <div className="block-text">{dtc.harness_description}</div>
      </div>
    </div>
  )
}

function isSelectedDtc(toggledOnDtc, currentDtc, module) {
  const dtcCodeMatched =
    get(toggledOnDtc, 'dtc_code') === get(currentDtc, 'dtc_code')
  const acronymMatched =
    get(toggledOnDtc, 'module.acronym') === get(module, 'acronym')

  return dtcCodeMatched && acronymMatched
}

function isDisabledBySelectedTab(currentTab) {
  return currentTab === PINOUT_VIEW || currentTab === VEHICLE_VIEW
}

function buildTitle(module, intl) {
  if (module.connector_alias) {
    return `${intl.formatMessage({ id: 'tracer.dtcListPanel.module' })} ${module.acronym} (${module.connector_alias})`
  }
  return `${intl.formatMessage({ id: 'tracer.dtcListPanel.module' })}  ${module.acronym}`
}

const DTCItem = (props) => {
  const {
    module,
    highlightedDtcs,
    toggledOnDtc,
    currentTab,
    onDtcClick,
    intl,
    makeModelId,
    isDtcMemoEditShown,
  } = props
  const [sortedDtcs, setSortedDtcs] = React.useState([])
  const [title, setTitle] = React.useState('')

  React.useEffect(() => {
    const comparator = Comparator.bind(null, 'asc', 'dtc_code')
    setSortedDtcs(module.dtcs.sort(comparator))
  }, [module])

  React.useEffect(() => {
    setTitle(buildTitle(module, intl))
  }, [module, intl])

  return (
    <ListPanel title={title} collapsible={true}>
      {sortedDtcs.map((dtc) => {
        const isSupported = dtc.is_supported
        const hasDtcMemo = !!dtc.memo && !!dtc.memo.memo
        if (!dtc.active) {
          return null
        }

        const indicated = highlightedDtcs.find((highlightedDtc) => {
          return (
            dtc.dtc_code === highlightedDtc.dtc_code &&
            dtc.module &&
            highlightedDtc.module &&
            dtc.module.acronym === highlightedDtc.module.acronym
          )
        })
        const isIndicated = !!indicated

        const isSelected = isSelectedDtc(toggledOnDtc, dtc, module)
        const isDisabled = isDisabledBySelectedTab(currentTab)
        const isMisconfigured = !!dtc.is_misconfigured
        const dtcCode = dtc.dtc_code
        const shortDtcCode =
          dtcCode.length > MAX_DTC_TITLE_LEN
            ? `${dtcCode.slice(0, 13)}...`
            : dtcCode
        const dtcDescription = getShortDescription(dtc, intl)

        const key = dtcItemKey(module, dtc)
        return (
          <Card>
            <ListItem
              id={key}
              key={key}
              handleListItemClick={() => onDtcClick(dtc)}
              indicate={isIndicated}
              misconfigured={isMisconfigured}
              selected={isSelected}
              disabled={isDisabled}
              height={80}
              unsupported={
                !isSupported && !isMisconfigured && dtc.memo && !dtc.memo.memo
              }
            >
              <DTCMemoPopup
                dtc={dtc}
                makeModelId={makeModelId}
                acronym={module.acronym}
                isSelected={isSelected}
              >
                <DTCMemoTooltip
                  dtc={dtc}
                  makeModelId={makeModelId}
                  acronym={module.acronym}
                  isSelected={isSelected}
                >
                  <div>
                    <Flex
                      className="dtc-title-container"
                      vertical={false}
                      align="center"
                      gap="small"
                    >
                      {isMisconfigured && (
                        <Popover
                          id={`misconfigured-dtc-popover-${dtc.dtc_code}`}
                          trigger="click"
                          placement="left"
                          zIndex={8000}
                          content={() => <MisconfiguredDTCContent dtc={dtc} />}
                          overlayStyle={{
                            width: '250px',
                          }}
                        >
                          <WarningOutlined className="warning-icon" />
                        </Popover>
                      )}
                      <span className="dtc-title">
                        <Flex vertical={false} gap="middle">
                          <Tooltip
                            title={dtcCode}
                            zIndex="9999"
                            placement="left"
                          >
                            {shortDtcCode}
                          </Tooltip>
                          {hasDtcMemo && <InfoCircleOutlined />}
                        </Flex>
                        {isDtcMemoEditShown && (
                          <MemoButton
                            dtc={dtc}
                            makeModelId={makeModelId}
                            acronym={module.acronym}
                            editIcon={EditIcon}
                            addIcon={AddIcon}
                          />
                        )}
                      </span>
                    </Flex>
                    <div className="dtc-subtitle">{dtcDescription}</div>
                  </div>
                </DTCMemoTooltip>
              </DTCMemoPopup>
            </ListItem>
          </Card>
        )
      })}
    </ListPanel>
  )
}

export default injectIntl(DTCItem)
