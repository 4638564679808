import React from 'react'
import { Input, Select, Flex, Button } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'

export const EmailField = injectIntl(
  ({ onChange, onBlur, value, error, intl }) => (
    <React.Fragment>
      <Flex vertical={true} gap="small">
        <label>
          <FormattedMessage id="users.email" />:
        </label>
        <Input
          type="email"
          name="email"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={intl.formatMessage({ id: 'users.email' })}
        />
      </Flex>
      {error && <div className="form-text-error">{error}</div>}
    </React.Fragment>
  ),
)

export const RoleField = injectIntl(
  ({ onChange, value, error, options, intl }) => {
    return (
      <React.Fragment>
        <Flex vertical={true} gap="small">
          <label>
            <FormattedMessage id="users.role" />:
          </label>
          <Select
            name="role"
            options={options}
            onChange={onChange}
            value={value == '' ? [] : value}
            placeholder={intl.formatMessage({ id: 'users.role' })}
          />
        </Flex>
        {error && <div className="form-text-error">{error}</div>}
      </React.Fragment>
    )
  },
)

export const PlantField = injectIntl(
  ({ role, options, onChange, value, error, intl }) => {
    return (
      <React.Fragment>
        <Flex vertical={true} gap="small">
          <label>
            <FormattedMessage id="users.plant" />:
          </label>
          <Select
            className="select"
            name="plant"
            options={options}
            onChange={onChange}
            mode="multiple"
            value={value}
            placeholder={intl.formatMessage({ id: 'users.plant' })}
          />
        </Flex>
        {error && <div className="form-text-error">{error}</div>}
      </React.Fragment>
    )
  },
)

export const DisabledField = injectIntl(
  ({ options, onChange, value, intl }) => (
    <Flex vertical={true} gap="small">
      <label>
        <FormattedMessage id="users.disabled" />:
      </label>
      <Select
        className="select"
        isClearable={false}
        name="disabled"
        options={options}
        onChange={onChange}
        value={value}
        placeholder={intl.formatMessage({ id: 'users.disabled' })}
      />
    </Flex>
  ),
)

export const Buttons = ({ isSaving }) => (
  <Flex vertical={false} gap="large" justify="center">
    <Button type="primary" disabled={isSaving}>
      {isSaving ? (
        <FormattedMessage id="processes.saving" />
      ) : (
        <FormattedMessage id="forms.save" />
      )}
    </Button>
    <Button
      type="default"
      href="/users"
      disabled={isSaving}
      className="user-form-cancel"
    >
      <FormattedMessage id="forms.cancel" />
    </Button>
  </Flex>
)
