import { connect } from 'react-redux'
import PinTable from '../components/PinTable'
import { getPinDestination } from '../api/controller-api'
import { setModal, setConnectorImage } from '../actions/modal-actions'
import { selectPin } from '../actions/pin-actions'
import { selectElement } from '../thunks/pin'
import {
  MODAL_PIN_DESTINATION,
  MODAL_CONNECTOR_VIEW,
  COMPONENT_CATEGORY_DEVICE,
} from '../constants'

const checkSupportedDtcs = (componentState) => {
  const componentId = componentState.selectedElement.id
  let isSupported = false

  componentState.modules.forEach((module) => {
    module.dtcs.forEach((dtc) => {
      if (dtc.components.has(componentId) && !dtc.is_supported) {
        isSupported = true
      }
    })
  })

  return isSupported
}

const mapStateToProps = (
  state,
  { component, showEmptyPinsToggle, showEmptyPinsDefault, open },
) => {
  return {
    showEmptyPinsToggle,
    open,
    showEmptyPinsDefault:
      showEmptyPinsDefault ||
      state.componentState.selectedElement.category ===
        COMPONENT_CATEGORY_DEVICE ||
      checkSupportedDtcs(state.componentState),
    selectedElement: component || state.componentState.selectedElement,
    vin: state.componentState.vin || component?.vin,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onPinClick: (pinObject, vin, component, pinNumber) => {
      getPinDestination(vin, component.id, pinNumber)
      dispatch(selectPin(pinNumber, pinObject, component.id))
      dispatch(selectElement(component))
      dispatch(setModal(MODAL_PIN_DESTINATION))
    },
    showConnectorModal: (pins, imageUrl) => {
      dispatch(setConnectorImage(pins, imageUrl))
      dispatch(setModal(MODAL_CONNECTOR_VIEW))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PinTable)
