import { useEffect, useRef } from 'react'
import { ICON_WIDTH, ICON_HEIGHT, ICON_WIDTH_OFFSET } from './constants'
import { ReactComponent as PowerDistributionIcon } from '../../assets/images/icons/svg/device-power-distribution.svg'
import { ReactComponent as ModuleIcon } from '../../assets/images/icons/svg/device-module.svg'
import { ReactComponent as MotorIcon } from '../../assets/images/icons/svg/device-motor.svg'
import { ReactComponent as SensorIcon } from '../../assets/images/icons/svg/device-sensor.svg'
import { ReactComponent as SwitchIcon } from '../../assets/images/icons/svg/device-switch.svg'
import { ReactComponent as AntennaIcon } from '../../assets/images/icons/svg/device-antenna.svg'
import { ReactComponent as SolenoidIcon } from '../../assets/images/icons/svg/device-solenoid.svg'
import { ReactComponent as ActuatorIcon } from '../../assets/images/icons/svg/device-actuator.svg'
import { ReactComponent as LampIcon } from '../../assets/images/icons/svg/device-lamp.svg'
import { ReactComponent as LedIcon } from '../../assets/images/icons/svg/device-led.svg'
import { ReactComponent as MicrophoneIcon } from '../../assets/images/icons/svg/device-microphone.svg'
import { ReactComponent as SpeakerIcon } from '../../assets/images/icons/svg/device-speaker.svg'
import { ReactComponent as CameraIcon } from '../../assets/images/icons/svg/device-camera.svg'
import { ReactComponent as UltrasonicIcon } from '../../assets/images/icons/svg/device-ultrasonic.svg'
import { ReactComponent as ThermistorIcon } from '../../assets/images/icons/svg/device-thermistor.svg'
import { ReactComponent as ProximityIcon } from '../../assets/images/icons/svg/device-proximity.svg'
import { ReactComponent as HallIcon } from '../../assets/images/icons/svg/device-hall.svg'
import { ReactComponent as BatteryIcon } from '../../assets/images/icons/svg/device-battery.svg'
import { ReactComponent as GeneratorIcon } from '../../assets/images/icons/svg/device-generator.svg'
import { ReactComponent as HeaterIcon } from '../../assets/images/icons/svg/device-heater.svg'
import { ReactComponent as ExternalMirrorIcon } from '../../assets/images/icons/svg/device-external-mirror.svg'
import { ReactComponent as InternalMirrorIcon } from '../../assets/images/icons/svg/device-internal-mirror.svg'
import { ReactComponent as BeltIcon } from '../../assets/images/icons/svg/device-belt.svg'
import { ReactComponent as SquibIcon } from '../../assets/images/icons/svg/device-squib.svg'
import { ReactComponent as InjectorIcon } from '../../assets/images/icons/svg/device-injector.svg'
import { DARK_MODE } from '../../constants/misc'
import { BLACK_COLOR, WHITE_COLOR } from '../../constants/colors'

const deviceIcons = new Map(
  Object.entries({
    power_distribution: PowerDistributionIcon,
    module: ModuleIcon,
    motor: MotorIcon,
    sensor: SensorIcon,
    switch: SwitchIcon,
    antenna: AntennaIcon,
    solenoid: SolenoidIcon,
    actuator: ActuatorIcon,
    lamp: LampIcon,
    led: LedIcon,
    microphone: MicrophoneIcon,
    speaker: SpeakerIcon,
    camera: CameraIcon,
    ultrasonic: UltrasonicIcon,
    thermistor: ThermistorIcon,
    proximity: ProximityIcon,
    hall: HallIcon,
    battery: BatteryIcon,
    generator: GeneratorIcon,
    heater: HeaterIcon,
    external_mirror: ExternalMirrorIcon,
    internal_mirror: InternalMirrorIcon,
    belt: BeltIcon,
    squib: SquibIcon,
    injector: InjectorIcon,
  }),
)

export default function Icon({ node, theme = null }) {
  const { width, height } = node
  const { category } = node.properties.data
  const svgRef = useRef(null)

  useEffect(() => {
    if (theme && svgRef.current !== null) {
      const childNodes = svgRef.current.childNodes
      console.log(childNodes)
      childNodes.forEach((element) => {
        alterColor(element)
        const elements = element.querySelectorAll('*')
        elements.forEach((element) => {
          alterColor(element)
        })
      })
    }
  }, [theme])

  const alterColor = (element) => {
    if (
      element.hasAttribute('fill') &&
      element.getAttribute('fill') != 'none'
    ) {
      element.setAttribute(
        'fill',
        theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
      )
    }

    if (
      element.hasAttribute('stroke') &&
      element.getAttribute('stroke') != 'none'
    ) {
      element.setAttribute(
        'stroke',
        theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
      )
    }
  }

  const SVGIcon = getIconByCategory(category)
  return SVGIcon ? (
    <SVGIcon
      x={width - ICON_WIDTH_OFFSET - ICON_WIDTH}
      y={height / 2 - ICON_HEIGHT / 2}
      height={ICON_HEIGHT}
      width={ICON_WIDTH}
      ref={svgRef}
    />
  ) : (
    ''
  )
}

export function getIconByCategory(category) {
  return deviceIcons.get(category) || null
}
