import React, { useState } from 'react'
import { injectIntl } from 'react-intl'

import { Input } from 'antd'

const MIN_SYMBOLS_TO_START_SEARCHING = 2

const Search = ({ makeSearchRequest, opts, intl }) => {
  const [query, setQuery, isLoading] = useState('')
  const handleInput = (e) => {
    const searchQuery = e.target ? e.target.value.trim() : ''
    setQuery(searchQuery)
    if (
      searchQuery.length >= MIN_SYMBOLS_TO_START_SEARCHING ||
      searchQuery === ''
    ) {
      makeSearchRequest({ ...opts, query: searchQuery })
    }
  }

  return (
    <div className="search-box">
      <Input
        disabled={isLoading}
        type="text"
        placeholder={intl.formatMessage({ id: 'userList.search' })}
        value={query}
        onChange={handleInput}
      />
    </div>
  )
}

export default injectIntl(Search)
