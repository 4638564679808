import React from 'react'
import PinTableContainer from '../containers/PinTableContainer'

const Pinout = ({
  component,
  showEmptyPinsToggle,
  showEmptyPinsDefault,
  isLoading,
  open,
}) => {
  if (!component) return <div />

  return (
    <PinTableContainer
      component={component}
      showEmptyPinsDefault={showEmptyPinsDefault}
      showEmptyPinsToggle={showEmptyPinsToggle}
      isLoading={isLoading}
      open={open}
    />
  )
}

export default Pinout
