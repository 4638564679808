import { formatTimestamp } from '../helpers/utils'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  navigateToVehicleTest,
  vehicleTestPayloadUrl,
} from '../api/vehicle-tests-api'
import MustHave from '../containers/MustHave'
import { CantFixMessage } from './CantFixReason'
import { postEmptyVehicleTest } from '../api/harness-api'
import { Button, Table, Flex } from 'antd'

const propTypes = {
  currentVehicleTest: PropTypes.object,
  data: PropTypes.array,
  onViewButtonClick: PropTypes.func,
  showVin: PropTypes.bool,
  style: PropTypes.object,
}

export function displayCantFix(text, record, index) {
  return (
    <span>
      <CantFixMessage vehicleTestId={record.id} />
    </span>
  )
}

const createColumns = (
  intl,
  showVin,
  currentVehicleTest,
  onViewButtonClick,
  createEmptyVehicleTest,
) => {
  const timeStampFormat = intl.formatMessage({ id: 'format.dayAndTime' })
  return [
    {
      title: intl.formatMessage({ id: 'generic.vin' }),
      dataIndex: ['vin'],
      hidden: showVin,
      key: 'vin',
    },
    {
      title: intl.formatMessage({ id: 'vinSearch.columns.date' }),
      render: (text, record, index) =>
        record.timestamp
          ? formatTimestamp(timeStampFormat, record.timestamp)
          : '--',
    },
    {
      title: intl.formatMessage({ id: 'vinSearch.columns.testType' }),
      render: (text, record, index) =>
        record.meta ? record.meta.test_type : null,
    },
    {
      title: intl.formatMessage({ id: 'vinSearch.columns.location' }),
      render: (text, record, index) =>
        record.test_zone_description
          ? record.test_zone_description
          : record.meta
            ? record.meta.hardware_location_id
            : null,
    },
    {
      title: intl.formatMessage({ id: 'vinSearch.columns.cantFix' }),
      render: displayCantFix,
    },
    {
      title: intl.formatMessage({ id: 'vinSearch.columns.user' }),
      render: (text, record, index) =>
        record.meta ? record.meta.user_id : null,
    },
    {
      title: '',
      render: (text, record, index) =>
        record.id ? (
          createActions(currentVehicleTest, intl, onViewButtonClick)(record)
        ) : (
          <Button onClick={createEmptyVehicleTest(record.vin)}>
            {intl.formatMessage({ id: 'repairHistory.titles.addFault' })}
          </Button>
        ),
    },
  ]
}

const createActions = (currentVehicleTest, intl, onViewButtonClick) => {
  const viewButton = (rowData) =>
    currentVehicleTest && currentVehicleTest.id === rowData.id ? (
      <p className="current-vehicle-test">
        {intl.formatMessage({ id: 'generic.current' })}
      </p>
    ) : (
      <Button
        className="view-other-test-button"
        type="primary"
        size="small"
        onClick={(e) => {
          navigateToVehicleTest({ vehicleTestId: rowData.id })
          if (onViewButtonClick) {
            onViewButtonClick(rowData.id)
          }
        }}
      >
        {intl.formatMessage({ id: 'vinSearch.buttons.tracer' })}
      </Button>
    )

  const downloadButton = (rowData) => (
    <MustHave permission="vehicle-test-download:get">
      <Button
        className="download-test-button"
        size="small"
        type="primary"
        href={vehicleTestPayloadUrl(rowData.id)}
      >
        {intl.formatMessage({ id: 'vinSearch.buttons.download' })}
      </Button>
    </MustHave>
  )

  return (rowData) => (
    <Flex vertical={false} justify="space-around" align="center">
      {viewButton(rowData)}
      {downloadButton(rowData)}
    </Flex>
  )
}

const defaultProps = {
  onViewButtonClick: undefined,
  currentVehicleTest: undefined,
  data: [],
  showVin: true,
}

function VehicleTestSearchTable({
  onViewButtonClick,
  currentVehicleTest,
  data,
  intl,
  showVin,
}) {
  const authToken = useSelector((state) => state.user.data.token, shallowEqual)
  const createEmptyVehicleTest = (vin) => () =>
    postEmptyVehicleTest(vin, authToken)

  return (
    <Table
      data-testid="vehicle-test-search-results"
      pagination={false}
      scroll={{
        y: 'calc(100vh - 114px)', // 114 is 64px (default height of the header) and 50 px (height of the table header)
      }}
      columns={createColumns(
        intl,
        showVin,
        currentVehicleTest,
        onViewButtonClick,
        createEmptyVehicleTest,
      )}
      dataSource={data}
    />
  )
}

VehicleTestSearchTable.propTypes = propTypes
VehicleTestSearchTable.defaultProps = defaultProps

export default injectIntl(VehicleTestSearchTable)
