import React, { Component } from 'react'
import * as ReactDOM from 'react-dom'
import cytoscape from 'cytoscape'
import popper from 'cytoscape-popper'
import dagre from 'cytoscape-dagre'
import tippy from 'tippy.js'

import { ZoomControl } from './ZoomControls'
import TooltipRoot from './HarnessViewGraphTooltipRoot'
import get from 'lodash/get'
import { getCyStyles } from '../helpers/cytoscapeStyle'
import { getNodeLabel } from '../helpers/utils'
import PropTypes from 'prop-types'
import { isArray, isFunction } from 'lodash'
import WebFont from 'webfontloader'
import {
  CLASS_NAME_ACTIVE,
  CLASS_NAME_FAULTY,
  HARNESS_VIEW,
} from '../constants'
import Loading from '../components/Loading'
import HarnessViewGraphTooltip from '../components/HarnessViewGraphTooltip'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

// Register cytoscape extensions
cytoscape.use(dagre)
cytoscape.use(popper)

const SELECTIVE_CLASSES = [CLASS_NAME_ACTIVE, CLASS_NAME_FAULTY]

const propTypes = {
  addPinsToComponents: PropTypes.bool,
  centerOnSelectedElement: PropTypes.bool,
  componentStates: PropTypes.object,
  connectorSet: PropTypes.object,
  elements: PropTypes.object,
  layout: PropTypes.object,
  onGraphEdgeSelected: PropTypes.func,
  onGraphElementSelected: PropTypes.func,
  onGraphNodeSelected: PropTypes.func,
  selectedElement: PropTypes.object,
  showPinDestinationLabels: PropTypes.bool,
  tooltipEnabled: PropTypes.bool,
}

export const defaultLayout = {
  acyclicer: 'greedy',
  directed: true,
  fit: true,
  name: 'dagre',
  padding: 20,
  pan: 'fit',
  nodeDimensionsIncludeLabels: true,
}

const defaultProps = {
  addPinsToComponents: false,
  centerOnSelectedElement: true,
  connectorSet: null,
  elements: {
    nodes: [],
    edges: [],
  },
  layout: defaultLayout,
  onGraphEdgeSelected: null,
  onGraphElementSelected: null,
  onGraphNodeSelected: null,
  selectedElement: null,
  showPinDestinationLabels: false,
  tooltipEnabled: false,
  panControls: null,
  zoomFactor: 0.25,
  userPanningEnabled: true,
  userZoomingEnabled: true,
}

class Cytoscape extends Component {
  constructor(props) {
    super(props)

    this.graphContainer = React.createRef()

    this.state = {
      graph: null,
    }
  }

  addLabelToElements(elements, componentStates) {
    for (const node of elements.nodes) {
      node.data['label'] = getNodeLabel(
        node.data,
        componentStates ? componentStates[node.id] : undefined,
      )
    }
    return elements
  }

  handleThemeChange = (theme) => {
    this.state.graph
      .style(
        getCyStyles({
          showPinDestinationLabels: this.props.showPinDestinationLabels,
          theme: theme,
        }),
      )
      .update()
  }

  componentDidMount() {
    // Custom fonts MUST be loaded before rending the cytoscape graph.
    WebFont.load({
      custom: {
        families: ['TracerIcons', 'Montserrat:n4,n5'],
      },
      active: this.initializeGraph,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.elements !== nextProps.elements && this.state.graph) {
      // Remove the elements so the layout will run correctly
      this.state.graph.elements().remove()
      this.state.graph.add(this.addLabelToElements(nextProps.elements))
      this.updateElementFocus(nextProps.connectorSet)
    } else {
      if (this.props.connectorSet !== nextProps.connectorSet) {
        this.updateElementFocus(nextProps.connectorSet)
      }
      if (this.props.componentStates !== nextProps.componentStates) {
        this.updateGraphLabels(nextProps.componentStates)
      }
    }
    if (nextProps.toggledOnDtc) {
      this.colorizeElements(nextProps.connectorSet)
    } else if (this.props.toggledOnDtc && !nextProps.toggledOnDtc) {
      this.decolorizeElements()
    }

    if (this.props.theme && this.props.theme != nextProps.theme) {
      this.handleThemeChange(nextProps.theme)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps.elements !== this.props.elements &&
      this.state.graph
    ) {
      // Run the cytoscape layout
      this.layout = this.state.graph.makeLayout(this.props.layout)
      this.layout.run()
    }

    const selectedElementIsChanged =
      prevProps.selectedElement !== this.props.selectedElement
    const componentStateIsChanged =
      prevProps.componentStates !== this.props.componentStates
    const graphWasLoaded = prevState.graph === null && this.state.graph !== null

    // the selection should be performed after update process to avoid blinking of the tooltip
    if (
      (selectedElementIsChanged || componentStateIsChanged || graphWasLoaded) &&
      this.state.graph
    ) {
      this.selectElement(prevProps.selectedElement, false)
      this.selectElement(this.props.selectedElement)
    }

    // If the side bar changes we need to a force a resize so it does not leave
    // a blank spot when you drag the graph to where the sidebar used to be.
    if (
      prevProps.sidebarOpen !== this.props.sidebarOpen &&
      prevProps.currentTab === HARNESS_VIEW &&
      this.state.graph
    ) {
      this.state.graph.resize()
    }

    if (
      prevProps.currentTab !== HARNESS_VIEW &&
      this.props.currentTab === HARNESS_VIEW &&
      this.state.graph
    ) {
      this.state.graph.resize()
      this.state.graph.center(this.props.selectedElement)
    }
  }

  componentWillUnmount() {
    if (this.state.graph) this.state.graph.destroy()
  }

  getGraphElementFromData(elementData) {
    if (!elementData || isFunction(elementData.data)) {
      return elementData
    } else {
      return this.state.graph.filter('#' + elementData.id)
    }
  }

  getDataFromGraphElement(
    element,
    addPinsToComponents = this.props.addPinsToComponents,
  ) {
    if (element && isFunction(element.data)) {
      const elementData = Object.assign({}, element.data())
      if (elementData) {
        elementData.isNode = element.isNode()
        elementData.isEdge = element.isEdge()
        elementData._addPinsToComponents = addPinsToComponents
        if (addPinsToComponents && elementData.isNode) {
          elementData.pins = this.getComponentPins(element)
        }
        if (elementData.isEdge) {
          elementData.harness_id = element.data('harness')
          elementData.harness_description = element.data('description')
        }
        return elementData
      }
    }
    return element
  }

  getCenterCoordinates = () => {
    return {
      x: this.state.graph.width() / 2,
      y: this.state.graph.height() / 2,
    }
  }

  getComponentPins(element) {
    const pins = {
      incoming: [],
      outgoing: [],
    }
    if (element.isNode()) {
      const incomingEdges = this.getIncomingEdges(element)
      const outgoingEdges = this.getOutgoingEdges(element)
      for (const edge of incomingEdges) {
        if (!edge.data('edge_type')) {
          const pinData = edge.data('pins')
          const colors = edge.data('colors')
          const color_desc = edge.data('color_desc')
          if (
            pinData &&
            pinData.length > 0 &&
            pinData[0] &&
            pinData[0].length > 1
          ) {
            pins.incoming.push({
              colors,
              color_desc,
              number: pinData[0][1],
              source_component: this.getDataFromGraphElement(
                this.state.graph.filter('#' + edge.data('source')),
                false,
              ),
              target_component: this.getDataFromGraphElement(
                this.state.graph.filter('#' + edge.data('target')),
                false,
              ),
            })
          }
        }
      }
      for (const edge of outgoingEdges) {
        if (!edge.data('edge_type')) {
          const pinData = edge.data('pins')
          const colors = edge.data('colors')
          const color_desc = edge.data('color_desc')
          if (
            pinData &&
            pinData.length > 0 &&
            pinData[0] &&
            pinData[0].length > 1
          ) {
            pins.outgoing.push({
              colors,
              color_desc,
              number: pinData[0][0],
              source_component: this.getDataFromGraphElement(
                this.state.graph.filter('#' + edge.data('source')),
                false,
              ),
              target_component: this.getDataFromGraphElement(
                this.state.graph.filter('#' + edge.data('target')),
                false,
              ),
            })
          }
        }
      }
    }
    return pins
  }

  getCssClassForState(element) {
    const elementId = element.data('id')
    const isDevice = element.data('category') === 'device'

    // if component has a status it can be only faulty status
    if (element.isNode()) {
      const states = isDevice
        ? this.props.deviceStates
        : this.props.componentStates
      const status = get(states, `[${elementId}].status`)

      if (status) {
        return CLASS_NAME_FAULTY
      }
    }

    return CLASS_NAME_ACTIVE
  }

  getIncomingEdges(element) {
    const incomers = element.incomers()
    const edges = []
    for (const key in incomers) {
      if (incomers.hasOwnProperty(key) && isFunction(incomers[key].data)) {
        if (incomers[key].isEdge()) {
          edges.push(incomers[key])
        }
      }
    }
    return edges
  }

  getOutgoingEdges(element) {
    const outgoers = element.outgoers()
    const edges = []
    for (const key in outgoers) {
      if (outgoers.hasOwnProperty(key) && isFunction(outgoers[key].data)) {
        if (outgoers[key].isEdge()) {
          edges.push(outgoers[key])
        }
      }
    }
    return edges
  }

  createContentFromComponent(component) {
    const dummyDomEle = document.createElement('div')
    ReactDOM.render(component, dummyDomEle)
    return dummyDomEle
  }

  showTooltip = ({
    graph,
    element,
    intl,
    componentStates,
    selectedElement,
    historiesForComponents,
  }) => {
    let ref = element.popperRef()
    let dummyDomEle = document.createElement('div')

    let tip = new tippy(dummyDomEle, {
      getReferenceClientRect: ref.getBoundingClientRect,
      trigger: 'manual',
      placement: 'right',
      allowHTML: true,
      interactive: true,
      theme: 'light',
      appendTo: () => document.body,
      content: () =>
        this.createContentFromComponent(
          <TooltipRoot>
            <HarnessViewGraphTooltip
              graph={graph}
              element={element}
              componentStates={componentStates}
              selectedElement={selectedElement}
              historiesForComponents={historiesForComponents}
            />
          </TooltipRoot>,
        ),
    })

    // Set Cytoscape scratchpad data.
    // More info: https://js.cytoscape.org/#ele.scratch
    element.scratch('_tip', tip)

    tip.show()
  }

  handleGraphTap = (event) => {
    if (event.target && event.target.isNode) {
      if (this.props.onGraphElementSelected) {
        const element = this.getDataFromGraphElement(
          event.target,
          this.props.addPinsToComponents,
        )
        this.props.onGraphElementSelected(element, this.props.dtcs)
      }
      if (this.props.centerOnSelectedElement) {
        this.state.graph.center(event.target)
      }
    }
    if (
      this.props.tooltipEnabled &&
      event.target &&
      event.target.data('category') !== 'splice' &&
      event.target.data('edge_type') !== 'inline' &&
      (event.target.isNode || event.target.isEdge)
    ) {
      this.showTooltip({
        graph: this.state.graph,
        element: event.target,
        intl: this.props.intl,
        componentStates: this.props.componentStates,
        selectedElement: this.props.selectedElement,
        historiesForComponents: this.props.historiesForComponents,
      })
    }
  }

  /**
   * Initialize the Cytoscape graph container after web-fonts have been loaded.
   */
  initializeGraph = () => {
    if (!isArray(this.props.elements.nodes)) {
      this.props.elements.nodes = []
    }
    if (!isArray(this.props.elements.edges)) {
      this.props.elements.edges = []
    }

    if (!this.graphContainer.current) return
    const graph = cytoscape({
      autounselectify: true,
      autoungrabify: true,
      boxSelectionEnabled: false,
      container: this.graphContainer.current,
      elements: this.addLabelToElements(this.props.elements),
      layout: this.props.layout,
      maxZoom: 5,
      minZoom: 0.1,
      selectionType: 'single',
      style: getCyStyles({
        showPinDestinationLabels: this.props.showPinDestinationLabels,
        theme: this.props.theme,
      }),
      userPanningEnabled: this.props.userPanningEnabled,
      userZoomingEnabled: this.props.userZoomingEnabled,
      zoomingEnabled: true,
    })

    if (graph) {
      // Map: harness -> set of edges (corresponding to harness)
      const edgesByHarness = {}

      graph.edges().forEach((edge) => {
        const harness = edge.data('harness')
        if (harness && !edge.data('virtual')) {
          edgesByHarness[harness] = edgesByHarness[harness] || new Set()
          edgesByHarness[harness].add(edge)
        }
      })

      this.setState(
        {
          graph,
          edgesByHarness,
        },
        () => {
          graph.on('tap', this.handleGraphTap)
        },
      )
    } else {
      console.error('An error occurred trying to create the Cytoscape graph.')
    }
  }

  render() {
    const ZoomIn = this.props.zoomInControl
    const ZoomOut = this.props.zoomOutControl
    const ZoomReset = this.props.zoomResetControl
    const PanControls = this.props.panControls

    return (
      <div className="graph-container">
        {!this.state.graph && <Loading />}
        <div
          data-testid="graph"
          id="digraph"
          className="graph"
          ref={this.graphContainer}
        />
        <ZoomControl
          zoomIn={this.zoomIn}
          zoomOut={this.zoomOut}
          zoomReset={this.zoomReset}
        />
        {PanControls && (
          <PanControls
            left={this.panLeft}
            right={this.panRight}
            up={this.panUp}
            down={this.panDown}
            disabled={!this.state.graph}
          />
        )}
      </div>
    )
  }

  selectElement(elementData, isSelect = true) {
    const element = this.getGraphElementFromData(elementData)

    if (!element) {
      return
    }

    if (!isSelect) {
      // Deselect node or edge.

      if (elementData?.isNode) {
        // TODO: why do we use filter instead getElementById? Could there be multiple elements with the same ID?
        this.state.graph
          .filter(`#${element.data('id')}`)
          .removeClass(SELECTIVE_CLASSES.join(' '))
      } else if (elementData?.isEdge) {
        this.state.edgesByHarness[elementData.harness]?.forEach((edge) => {
          edge.removeClass(CLASS_NAME_ACTIVE)
        })
      }
    } else {
      // Select node or edge.
      if (elementData?.isNode) {
        if (
          this.props.addPinsToComponents &&
          !elementData._addPinsToComponents
        ) {
          // The selected component is missing pin data, let's add it
          this.props.onGraphElementSelected(
            this.getDataFromGraphElement(
              element,
              this.props.addPinsToComponents,
            ),
          )
        }

        // Highlight node.
        this.state.graph
          .filter(`#${element.data('id')}`)
          .addClass(this.getCssClassForState(element))
      } else if (elementData?.isEdge) {
        // Highlight edge and other edges with the same harness value.
        this.state.edgesByHarness[elementData.harness]?.forEach((edge) => {
          edge.addClass(CLASS_NAME_ACTIVE)
        })
      }

      if (this.props.centerOnSelectedElement) {
        this.state.graph.center(element)
      }
    }
  }

  markElement(element, isSelect = true) {
    if (!element) {
      return
    }

    if (isSelect) {
      element.data(this.getCssClassForState(element), true)
    } else {
      SELECTIVE_CLASSES.map((className) => element.data(className, false))
    }
  }

  updateElementFocus(connectorSet) {
    if (this.state.graph && connectorSet) {
      this.state.graph.batch(() => {
        this.state.graph.nodes().forEach((node) => {
          this.unfocusNodeAndEdges(
            node,
            connectorSet.size !== 0 && !connectorSet.has(node.data('id')),
          )
        })
      })
    }
  }

  unfocusNodeAndEdges(node, unfocus = true) {
    node.data('unfocus', unfocus)
    this.getOutgoingEdges(node).forEach((edge) => {
      edge.data('unfocus', unfocus)
    })
    this.getIncomingEdges(node).forEach((edge) => {
      edge.data('unfocus', unfocus)
    })
  }

  colorizeElements(connectorSet) {
    if (this.state.graph && connectorSet) {
      this.state.graph.batch(() => {
        this.state.graph.nodes().forEach((node) => {
          this.markElement(
            node,
            connectorSet.size !== 0 && connectorSet.has(node.data('id')),
          )
        })
      })
    }
  }

  decolorizeElements() {
    if (this.state.graph) {
      this.state.graph.batch(() => {
        this.state.graph.nodes().forEach((node) => {
          this.markElement(node, false)
        })
      })
    }
  }

  updateGraphLabels(componentStates) {
    if (this.state.graph && componentStates) {
      this.state.graph.batch(() => {
        this.state.graph.nodes().forEach((node) => {
          node.data(
            'label',
            getNodeLabel(node.data(), componentStates[node.data('id')]),
          )
        })
      })
    }
  }

  zoomIn = () => {
    this.state.graph.zoom({
      level: this.state.graph.zoom() + this.props.zoomFactor,
      renderedPosition: this.getCenterCoordinates(),
    })
  }

  panRight = ({ step = 50 } = {}) => {
    this.state.graph.panBy({
      x: -step,
      y: 0,
    })
  }

  panLeft = ({ step = 50 } = {}) => {
    this.state.graph.panBy({
      x: step,
      y: 0,
    })
  }

  panDown = ({ step = 50 } = {}) => {
    this.state.graph.panBy({
      x: 0,
      y: -step,
    })
  }

  panUp = ({ step = 50 } = {}) => {
    this.state.graph.panBy({
      x: 0,
      y: step,
    })
  }

  zoomOut = () => {
    this.state.graph.zoom({
      level: this.state.graph.zoom() - this.props.zoomFactor,
      renderedPosition: this.getCenterCoordinates(),
    })
  }

  zoomReset = () => {
    if (!this.props.selectedElement) return
    const node = this.state.graph
      .nodes(`node[id="${this.props.selectedElement.id}"]`)
      .first()
    this.state.graph.center(node)
  }
}

Cytoscape.defaultProps = defaultProps
Cytoscape.propTypes = propTypes

export default Cytoscape
