import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { useSearchSubsystems } from '../../BlockDiagram/hooks'
import { FormattedMessage } from 'react-intl'

export const RelatedSubsystemsButton = ({
  showButtonText,
  vin,
  componentId,
  contextComponentId,
  componentIdDispatch,
}) => {
  const [componentID, updateComponentID] = useState(null)
  const { isLoading, searchSubsystems } = useSearchSubsystems({
    vin,
    componentId: componentID,
  })

  const updateConnectorIdFilterSubsystem = () => {
    if (!componentID) {
      updateComponentID(componentId)
    } else {
      toggleSubsystemTable()
    }
  }

  useEffect(() => {
    if (!isLoading && searchSubsystems) {
      toggleSubsystemTable()
    }
  }, [searchSubsystems])

  const toggleSubsystemTable = () => {
    const id = `subsystem-${componentId}-button`
    const diagramSubsystemID = `subsystem-${contextComponentId}-button`
    const buttonId =
      !diagramSubsystemID || id !== diagramSubsystemID ? componentId : null
    componentIdDispatch({
      relatedSubsystemComponentID: buttonId,
    })
  }

  const toggleButtonType = () => {
    return (
      !contextComponentId ||
      `subsystem-${componentId}-button` !==
        `subsystem-${contextComponentId}-button`
    )
  }

  return (
    <div className="subsystems-search-button">
      <Button
        id={`subsystem-${componentId}-button`}
        disabled={isLoading}
        icon={isLoading ? <LoadingOutlined /> : <SearchOutlined />}
        onClick={updateConnectorIdFilterSubsystem}
        data-testid="component-id-subsystem-toggle"
        type={toggleButtonType() ? 'default' : 'primary'}
      >
        {showButtonText && (
          <FormattedMessage id="subsystemButton.relatedText" />
        )}
      </Button>
    </div>
  )
}
