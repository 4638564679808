import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import history from '../history'
import { Popover } from 'antd'
import IconHamburgerMenu from './Icons/IconHamburgerMenu'

const MAX_HEIGHT = 120 // px
const UPWARDS_PADDING = 5 // px

const propTypes = {
  menuOptions: PropTypes.array,
}

const defaultProps = {
  menuOptions: [],
}

function MoreMenu({ isOpen, setIsOpen, menuOptions, isDisabled = false }) {
  const moreMenuListNode = useRef()

  useEffect(() => {
    const unlistenToHistory = history.listen((location, action) => {
      if (isOpen) {
        setIsOpen(false)
      }
    })

    return () => {
      unlistenToHistory()
    }
  }, [isOpen, setIsOpen])

  const clazz = classNames('more-menu', {
    active: isOpen,
    disabled: isDisabled,
  })

  return (
    <Popover
      content={<div className="more-menu-detail">{menuOptions}</div>}
      trigger="click"
      placement="right"
      open={isOpen}
      overlayStyle={{
        width: '220px',
      }}
      onOpenChange={() => setIsOpen(!isOpen)}
    >
      <div className={clazz} ref={moreMenuListNode}>
        <div
          className="more-menu-icon"
          onClick={() => !isDisabled && setIsOpen(!isOpen)}
        >
          <IconHamburgerMenu />
        </div>
      </div>
    </Popover>
  )
}

MoreMenu.propTypes = propTypes
MoreMenu.defaultProps = defaultProps

export default MoreMenu
