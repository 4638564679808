export const toggleColor = (childNodes, color) => {
  childNodes.forEach((element) => {
    if (element.id != 'BKG') {
      const elements = element.querySelectorAll('*')

      elements.forEach((element) => {
        if (element.parentElement.id == 'Layout' && element.tagName == 'text') {
          return
        }

        if (element.parentElement.id == 'Connectors') {
          if (element.tagName == 'text') element.setAttribute('fill', color)

          return
        }

        if (element.hasAttribute('fill') && checkColor(element, 'fill')) {
          element.setAttribute('fill', color)
        }

        if (element.hasAttribute('stroke') && checkColor(element, 'stroke')) {
          element.setAttribute('stroke', color)
        }
      })
    }
  })
}

const checkColor = (element, type) => {
  return (
    element.getAttribute(type) == 'white' ||
    element.getAttribute(type) == 'WHITE' ||
    element.getAttribute(type) == '#FFFFFF' ||
    element.getAttribute(type) == '#ffffff' ||
    element.getAttribute(type) == 'black' ||
    element.getAttribute(type) == 'BLACK' ||
    element.getAttribute(type) == '#000000'
  )
}
