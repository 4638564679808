import React from 'react'
import PropTypes from 'prop-types'

import { Radio } from 'antd'
import {
  PlusOutlined,
  MinusOutlined,
  CompressOutlined,
} from '@ant-design/icons'

export const ZoomControl = ({
  zoomIn,
  zoomOut,
  zoomReset,
  className = null,
}) => (
  <Radio.Group
    value=""
    className={className ? className : 'zoom-control-button-group'}
  >
    {zoomIn && (
      <Radio.Button onClick={zoomIn}>
        <PlusOutlined />
      </Radio.Button>
    )}
    {zoomReset && (
      <Radio.Button onClick={zoomReset}>
        <CompressOutlined />
      </Radio.Button>
    )}
    {zoomOut && (
      <Radio.Button onClick={zoomOut}>
        <MinusOutlined />
      </Radio.Button>
    )}
  </Radio.Group>
)
