import React, { Component } from 'react'
import InlineSvg from 'react-inlinesvg'
import svgPanZoom from 'svg-pan-zoom'
import Hammer from 'hammerjs'
import Loading from '../Loading'
import './style.scss'
import { BLACK_COLOR, WHITE_COLOR } from '../../constants/colors'
import { DARK_MODE } from '../../constants/misc'
import { toggleColor } from '../SVGColorToggle'

const defaultProps = {
  zoomEnabled: true,
  panEnabled: true,
}

class SvgViewer extends Component {
  hammer = null
  initialScale = null
  panzoom = null
  svg = null

  render() {
    const srcIsInvalid = !this.props.src || this.props.src.includes('/null')
    const src = srcIsInvalid ? '' : this.props.src
    const className = this.props.className || 'svg-viewer'
    return (
      <div
        className={className}
        ref={(container) => (this.container = container)}
      >
        <InlineSvg
          src={src}
          onLoad={this.onSvgLoadedInDom}
          uniquifyIDs={false}
          cacheRequests={false}
          fetchOptions={{ credentials: 'include' }}
          loader={<Loading showText={false} />}
        >
          {this.props.errorComponent}
        </InlineSvg>
      </div>
    )
  }

  componentWillUnmount() {
    if (this.panzoom) this.panzoom.destroy()
  }

  onSvgLoadedInDom = () => {
    this.svg = this.getSvgContainer()
    if (this.props.theme) {
      this.toggleTheme(this.svg, this.props.theme)
    }
    if (this.props.panzoom) {
      this.initializePanZoom(this.svg)
    }

    if (this.props.onReady) {
      this.props.onReady(this.svg, this.panzoom)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.theme && this.props.theme != nextProps.theme) {
      this.toggleTheme(this.getSvgContainer(), nextProps.theme)
    }
  }

  toggleTheme = (svgRef, theme) => {
    const color = theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR
    // const styleTag = svgRef.querySelector('style');
    // const tempSheet = new CSSStyleSheet();
    // tempSheet.replaceSync(styleTag.textContent);

    // for (let rule of tempSheet.cssRules) {
    //   if(rule.style.fill && rule.style.fill != "" && rule.style.fill != "none"){
    //     rule.style.fill = color
    //   }

    //   if(rule.style.stroke && rule.style.stroke != "" && rule.style.stroke != "none"){
    //     rule.style.stroke = color
    //   }
    // }
    // styleTag.textContent = [...tempSheet.cssRules].map(rule => rule.cssText).join(' ')

    if (svgRef && svgRef.childNodes) {
      const childNodes = svgRef.childNodes
      toggleColor(childNodes, color)
    }
  }

  getSvgContainer = () => {
    return this.container.getElementsByTagName('svg')[0]
  }

  eventHandler = {
    haltEventListeners: [
      'touchstart',
      'touchend',
      'touchmove',
      'touchleave',
      'touchcancel',
    ],
    init: (options) => {
      let initialScale = 1
      let pannedX = 0
      let pannedY = 0

      this.hammer = new Hammer(options.svgElement, {
        inputClass: Hammer.SUPPORT_POINTER_EVENTS
          ? Hammer.PointerEventInput
          : Hammer.TouchInput,
      })

      this.hammer.get('pinch').set({ enable: true })

      this.hammer.on('doubletap', function (ev) {
        options.instance.zoomIn()
      })

      this.hammer.on('panstart panmove', function (ev) {
        // On pan start reset panned variables
        if (ev.type === 'panstart') {
          pannedX = 0
          pannedY = 0
        }

        // Pan only the difference
        options.instance.panBy({
          x: ev.deltaX - pannedX,
          y: ev.deltaY - pannedY,
        })
        pannedX = ev.deltaX
        pannedY = ev.deltaY
      })

      // Handle pinch
      this.hammer.on('pinchstart pinchmove', function (ev) {
        // On pinch start remember initial zoom
        if (ev.type === 'pinchstart') {
          initialScale = options.instance.getZoom()
          options.instance.zoom(initialScale * ev.scale)
        }

        options.instance.zoom(initialScale * ev.scale)
      })

      // Prevent moving the page on some devices when panning over SVG
      options.svgElement.addEventListener('touchmove', function (e) {
        e.preventDefault()
      })
    },
    destroy: () => {
      this.hammer.destroy()
    },
  }

  initializePanZoom = (svg) => {
    this.panzoom = svgPanZoom(svg, {
      contain: true,
      fit: true,
      zoomEnabled: this.props.zoomEnabled,
      panEnabled: this.props.panEnabled,
      customEventsHandler: this.eventHandler,
    })
  }
}

SvgViewer.defaultProps = defaultProps

export default SvgViewer
