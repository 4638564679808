import React, { Component } from 'react'
import { List, Card } from 'antd'

export const VehicleDetails = ({ salesCodeConstraints }) => {
  return (
    <Card title="Vehicle details" className="vehicle-details">
      <div id="vehicle-details-container">
        <List
          size="small"
          rowKey={'pin'}
          dataSource={salesCodeConstraints}
          grid={{ column: 2 }}
          renderItem={(item) => (
            <List.Item>
              <h5>{item.group.description}:</h5>
              {item.sales_code.description}
            </List.Item>
          )}
        />
      </div>
    </Card>
  )
}

export default VehicleDetails
