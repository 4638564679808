import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMeasure } from 'react-use'
import { connect } from 'react-redux'

import { getElementName } from '../../helpers/element'
import Header from '../Header'
import { ZoomControl } from '../ZoomControls'
import SvgViewer from './SvgViewer'
import { useCDN } from '../../hooks/CDN'
import './style.scss'
import { ConnectorPreview } from '../ConnectorPreview'
import { Radio } from 'antd'
import { ErrorComponent } from '../ErrorImageComponent'

export function Topology({
  component,
  zoomInControl,
  zoomOutControl,
  zoomResetControl,
  panControls,
  zoomScaleSensitivity = 0.2,
  minZoomLevel = 0.3,
  panEnabled = true,
  zoomEnabled = true,
  theme,
  open = false,
}) {
  const { isSuccess, cdn } = useCDN()
  const [circle, setCircle] = React.useState(null)
  const [circleRadius, setCircleRadius] = React.useState(null)
  const [circleStroke, setCircleStroke] = React.useState(null)
  const [svg, setSvg] = React.useState(null)
  const [panZoom, setPanZoom] = React.useState(null)
  const [measureRef, { width, height }] = useMeasure()
  const [zoom, setZoom] = React.useState(1)

  React.useEffect(() => {
    const topologyContainer = document.getElementById('topology')
    if (topologyContainer) {
      if (open) {
        topologyContainer.style.width = 'calc(100% - 278px)'
      } else {
        topologyContainer.style.width = '100%'
      }
    }
  }, [open])

  React.useEffect(() => {
    if (!svg) return
    if (!component) return
    const circle = findCircleElement({ svg, component })
    if (circle) {
      setCircleRadius(Number(circle.getAttribute('r')) * 3)
      setCircleStroke(Number(circle.getAttribute('stroke-width')) * 2)
      setCircle(circle)
    }
  }, [svg, component])

  React.useEffect(() => {
    if (!circle) return
    circle.setAttribute('stroke-opacity', '1')
    circle.setAttribute('fill-opacity', '0.20')
  }, [circle])

  React.useEffect(() => {
    if (circle) {
      circle.setAttribute('r', circleRadius / zoom)
      circle.setAttribute('stroke-width', circleStroke / zoom)
    }
  }, [circle, circleRadius, circleStroke, zoom])

  const PanControls = panControls

  const getFileName = () => {
    if (component.isEdge) {
      return component.image_file_name
    } else if (component.isNode) {
      return component.images.vehicle_file_name
    }
    return null
  }

  const getTitle = () => {
    if (component.isNode) {
      return getElementName(component)
    } else if (component.isEdge) {
      return component.harness_id
    }
    return null
  }

  const getSubTitle = () => {
    if (component.isNode) {
      return null
    } else if (component.isEdge) {
      return component.level
    }
    return null
  }

  const getErrorComponent = () => {
    return (
      <div className="no-svg-container">
        <ErrorComponent>
          <h2>
            <FormattedMessage id="tracer.vehicleView.errorComponent.header" />
          </h2>
          <p>
            <FormattedMessage
              id="tracer.vehicleView.errorComponent.message"
              values={{ component: getElementName(component) }}
            />
          </p>
        </ErrorComponent>
      </div>
    )
  }

  /**
   * Center on the selected component in the rendered SVG
   */
  const center = () => {
    const sizes = panZoom.getSizes()
    if (circle) {
      panZoom.pan({
        x:
          -(parseFloat(circle.getAttribute('cx')) * sizes.realZoom) + width / 2,
        y:
          -(parseFloat(circle.getAttribute('cy')) * sizes.realZoom) +
          height / 2,
      })
    } else {
      panZoom.resize()
      panZoom.fit()
      panZoom.center()
    }
  }

  const findCircleElement = ({ svg, component }) => {
    const ids = [component.id, component.sibling]
    for (const id of ids) {
      const circleElementId = `_${id}`
      const circleElement = svg.getElementById(circleElementId)
      if (circleElement) {
        return circleElement
      }
    }
    return null
  }

  const onBeforeZoom = (prevZoom, newZoom) => {
    setZoom(newZoom)
  }

  const onSvgReady = (svg, panZoom) => {
    // Max out the size of the svg container
    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')

    // Register panzoom events
    panZoom.setBeforeZoom(onBeforeZoom)
    panZoom.setZoomScaleSensitivity(zoomScaleSensitivity)
    panZoom.setMinZoom(minZoomLevel)

    // Center the SVG and have it fit its container
    panZoom.resize()
    panZoom.fit()
    panZoom.center()

    setSvg(svg)
    setPanZoom(panZoom)
  }

  /**
   * Zoom in using the on-screen control.
   */
  const zoomIn = () => {
    panZoom.zoomIn()
  }

  /**
   * Zoom out using the on-screen control.
   */
  const zoomOut = () => {
    panZoom.zoomOut()
  }

  const panRight = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: -step,
      y: 0,
    })
  }

  const panLeft = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: step,
      y: 0,
    })
  }

  const panDown = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: 0,
      y: -step,
    })
  }

  const panUp = ({ step = 50 } = {}) => {
    panZoom.panBy({
      x: 0,
      y: step,
    })
  }

  return (
    <>
      {isSuccess && component && (
        <>
          <Header
            title={getTitle()}
            extendedtitle={getSubTitle()}
            subtitle={component.description}
          />
          <div id="topology">
            <div
              ref={measureRef}
              id="topology-svg-container"
              className="overflow-hidden"
            >
              <ConnectorPreview
                title={component?.alias}
                colorCode={component?.color}
                fileName={component?.images?.connector_file_name}
                theme={theme}
              />
              <SvgViewer
                errorComponent={getErrorComponent()}
                key={component.id}
                src={`${cdn.imagesURL}/${getFileName()}`}
                onReady={onSvgReady}
                panzoom={true}
                panEnabled={panEnabled}
                zoomEnabled={zoomEnabled}
              />
            </div>
            <>
              <ZoomControl
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                zoomReset={center}
              />
              {PanControls && (
                <PanControls
                  left={panLeft}
                  right={panRight}
                  up={panUp}
                  down={panDown}
                />
              )}
            </>
          </div>
        </>
      )}
      {!component && (
        <>
          <Header title="" subtitle="" extendedtitle="" />
          <div id="topology">
            <div id="topology-svg-container" className="overflow-hidden">
              <div className="no-svg-container">
                <ErrorComponent>
                  <h2>
                    <FormattedMessage id="tracer.vehicleView.noComponent.header" />
                  </h2>
                  <p>
                    <FormattedMessage id="tracer.vehicleView.noComponent.message" />
                  </p>
                </ErrorComponent>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    component: ownProps.component || state.componentState.selectedElement,
    theme: state.app.theme,
  }
}

export default connect(mapStateToProps)(Topology)
