import React from 'react'
import { Flex, Carousel, Row } from 'antd'

const DashboardCarousel = ({ elements, perPage = 6 }) => {
  const nonNullElements = elements.filter((e) => e)
  const totalPages = Math.ceil(nonNullElements.length / perPage)
  const pages = [...Array(totalPages).keys()]

  return (
    <Flex
      vertical={false}
      align="center"
      className="main-dashboard-container"
      data-testid="main-dashboard"
    >
      <Carousel
        arrows
        infinite={false}
        adaptiveHeight={true}
        waitForAnimate={true}
      >
        {pages.map((page) => (
          <div className="page-wrapper">
            <Row gutter={[16, 0]} key={page} className="row-wrapper">
              {nonNullElements.slice(page * perPage, (page + 1) * perPage)}
            </Row>
          </div>
        ))}
      </Carousel>
    </Flex>
  )
}

export default DashboardCarousel
