import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Table, Flex, Button, Typography } from 'antd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'

import Search from './Search'
import { loadMoreUsers, loadUsersList } from '../../thunks/users'
import { requireAuthentication } from '../../helpers/authentication'
import { Loadable } from '../Loading'

function TableBS(props) {
  const { Link } = Typography
  const { users = [], pagination = null } = props.usersData

  const columns = [
    {
      title: <FormattedMessage id="userList.email" />,
      width: '32%',
      render: (record) => {
        return (
          <Link className="edit-user-link" href={`/users/${record.id}`}>
            {record.email}
          </Link>
        )
      },
    },
    {
      title: <FormattedMessage id="userList.fullName" />,
      dataIndex: 'name',
      width: '33%',
    },
    {
      title: <FormattedMessage id="userList.role" />,
      dataIndex: 'role',
      ellipsis: true,
    },
    {
      title: <FormattedMessage id="userList.plants" />,
      render: (record) => {
        if (record.plants && record.plants.length > 0) {
          return record.plants.join(', ')
        }
        return ''
      },
    },
    {
      title: <FormattedMessage id="userList.disabled" />,
      render: (record) =>
        record.disabled ? (
          <FormattedMessage id="generic.yes" />
        ) : (
          <FormattedMessage id="generic.no" />
        ),
    },
  ]
  return (
    <div className="user-list-table-container">
      <Table
        dataSource={users}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
    </div>
  )
}

function UserList() {
  const users = useSelector((state) => state.users?.data, shallowEqual)
  const isLoading = useSelector((state) => state.users?.isLoading, shallowEqual)
  const isMoreUsersLoading = useSelector(
    (state) => state.users?.isMoreUsersLoading,
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUsersList())
  }, [])

  const query = users?.query
  const paginationToken = users?.pagination_token
  return (
    <Flex vertical={true} className="user-list-container" gap="large">
      <Flex vertical={false} justify="center" gap="large">
        <Button type="primary" href="/users/add" className="add-user-button">
          <FormattedMessage id="users.addNewUser" />
        </Button>
        <Search
          makeSearchRequest={debounce(
            (params) => dispatch(loadUsersList(params)),
            250,
          )}
          isLoading={isLoading}
          opts={{ query }}
        />
      </Flex>
      <Flex vertical={true} align="center">
        <Loadable isLoading={isLoading} showText={true}>
          <TableBS usersData={users} isLoading={isLoading} />
          {paginationToken ? (
            <Button
              type="primary"
              disabled={isMoreUsersLoading}
              onClick={() =>
                dispatch(loadMoreUsers({ query, pagination: paginationToken }))
              }
            >
              <FormattedMessage id="processes.loadMoreData" />
            </Button>
          ) : null}
        </Loadable>
      </Flex>
    </Flex>
  )
}

export default requireAuthentication(injectIntl(UserList))
